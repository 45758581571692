//Background Colours
$primary-colour: #320022;
$secondary-colour: rgb(245, 245, 245);

//Text Colours
$primary-text-colour: #a6a6a4;
$secondary-text-colour: #141414;

//Text Fonts
// $primary-text-font: "Georgia", serif;
$primary-text-font: "Roboto", sans-serif;
$title-text-font: "Lora", serif;

// font-family: "Roboto", sans-serif;
// font-weight: 300;
