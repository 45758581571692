@import "./branding";

.no-scroll {
  overflow: hidden;
}

.full-screen {
  min-height: 100vh;
}

.height100vh {
  height: 100vh;
}

.minheight60vh {
  min-height: 60vh;
}

.minwidth10vw {
  min-width: 10vw;
}

.primary-background {
  background-color: $primary-colour;
}

.half-screen {
  min-height: 50vh;
}

.quarter-screen {
  min-height: 25vh;
}

.width-full {
  width: 100%;
}

.height-full {
  height: 100%;
}

.primary-background {
  background-color: $primary-colour;
}

.flex-box-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.flex-box-around {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.flex-box-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.flex-box-left {
  display: flex;
  justify-content: left;
  flex-direction: row;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-box-column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.flex-box-column-around {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.flex-box-column-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.height15vh {
  height: 15vh;
}

.thick-bottom-border {
  border-bottom: 0.5vh solid $secondary-colour;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.fixed {
  position: fixed;
  top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $title-text-font;
  color: $primary-text-colour;
  font-weight: 10;
  letter-spacing: 0.1vw;
  margin-top: 0;
}

h1 {
  font-size: 4vw;
}

h2 {
  font-size: 3.5vw;
}

h3 {
  font-size: 3vw;
}

h4 {
  font-size: 2.5vw;
}

h5 {
  font-size: 2vw;
}

h6 {
  font-size: 1.5vw;
}

h1, h2.page-title {
  padding: 2.5vh 0 2.5vh 0;
}

p,
text {
  font-family: $primary-text-font;
  color: $primary-text-colour;
  margin: 0;
  font-size: 1.25vw;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.inline {
  display: inline;
}

.hidden {
  display: none;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.paddingTop5vh {
  padding-top: 5vh;
}

.marginTop5vh {
  margin-bottom: 5vh;
}

*,
::after,
::before {
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}
