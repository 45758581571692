@import "./branding";

//Media Queries: 1400px
@media screen and (max-width: 1500px) {
  .work-with-us-page-container {
    height: 190vh !important;
    .work-info-container-right {
      width: 75vw;
    }

    .contact-info-container {
      width: 100vw !important;
    }
  }
}

//Media Queries: 1200px
@media screen and (max-width: 1300px) {
  .work-container {
    .work-sub-container {
      .work-text {
        p {
          // font-size: 1em;
        }
      }
    }
  }

  .team-container {
    flex-direction: column !important;

    .team-member-container {
      contactcontainer {
        padding-right: 25vw;
        padding-left: 25vw;
      }
    }
  }
}

//Media Queries: 1100px
@media screen and (max-width: 1100px) {
  .work-for-us-page-container {
    .info-paragraph {
      .work-info-list-container {
        .work-info-list ul {
          top: 0 !important;
          li {
            // font-size: 1.1em !important;
          }
        }
      }
    }
  }

  .work-with-us-page-container {
    .info-paragraph {
      .work-info-list-container {
        .work-info-list ul {
          top: 0 !important;
          li {
            // font-size: 1.1em !important;
          }
        }
      }
    }
  }
}

//Media Queries: 1000px
@media screen and (max-width: 1000px) {
  .static-menu {
    display: none !important;
  }

  .slideInMenu {
    display: block;
  }

  .nav-home {
    position: fixed;
    border-bottom: 0.5vh solid $secondary-colour;
  }

  .nav-bar {
    height: 9vh;

    .ul1 {
      width: 100%;
    }

    ul {
      li {
        padding: 0;

        .ul2 {
          position: relative !important;
          background: $primary-colour;

          li {
            background: $secondary-colour;
            a {
              color: $primary-colour;
            }
          }
        }
      }
    }
  }

  .work-container {
    flex-direction: column !important;
  }

  .footer-container {
    section1 {
      flex-direction: column !important;
    }

    .contact-details-container {
      flex-direction: column !important;
      links1 {
        padding: 0 25vw !important;
      }
      links2 {
        padding: 0 25vw !important;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .talent {
    width: 100%;

    //   h3, h4, text {
    //     padding: .25em !important;
    //     transform: scale(0.5);
    // }
  }
}

//Media Queries: 420px
@media screen and (max-width: 420px) {
  body {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }

  .logo-only-container {
    height: 42vh;
    img {
      position: relative;
      height: 35vh;
      top: 8vh;
    }
  }

  .text-only-container {
    h1 {
      // font-size: 1.5em;
    }

    h3 {
      // font-size: 1em;
    }
  }

  // Work for/with us section
  .work-container {
    height: 200vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .work-sub-container {
      width: 100vw;
      height: 100vh;

      .work-for-us-image-container {
        img {
          width: 100vw;
        }
      }

      .work-with-us-image-container {
        img {
          width: 100vw;
        }
      }
    }

    .work-title {
      h1 {
        // font-size: 2.5em;
      }
    }

    .work-text {
      margin-left: 7.5vw;
      margin-right: 7.5vw;

      p {
        // font-size: 0.95em !important;
      }
    }
  }

  .work-for-us-page-container {
    .work-info-list-container {
      .work-info-list {
        li {
          // font-size: 0.75em !important;
        }
      }
    }

    p {
      // font-size: 0.75em;
    }

    .contact-info-container {
      margin-top: 5vh !important;
    }
  }

  .work-with-us-page-container {
    .work-info-list-container {
      .work-info-list {
        li {
          // font-size: 0.75em !important;
        }
      }
    }

    .info-paragraph {
      .work-info-container .work-info-container-left {
        .work-info {
          p {
            // font-size: 0.75em;
          }
        }
      }
    }

    .contact-info-container {
      margin-top: 5vh !important;
    }
  }

  .contact-details-container {
    links1 {
      padding: 0 12.5vw !important;
    }
    links2 {
      padding: 0 12.5vw !important;
    }
  }
}

@media screen and (max-width: 320px) {
  .about-container {
    height: 250vh;
    .about-text {
      .about-sub-text {
        height: 40vh;
      }
    }
  }
}
