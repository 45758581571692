@import "./branding";

// Footer Section
.footer-container {
  background-image: linear-gradient($secondary-colour, $primary-text-colour);
  min-height: 25vh;

  section1 {
    min-height: 20vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .dmt-friends-container {
      padding: 1.25vh 0 1.25vh 0;
      .img-container {
        img {
          height: 15vh;
        }
      }
    }

    .em-logo-container {
      padding: 1.25vh 0 1.25vh 0;
      .img-container {
        img {
          height: 12.5vh;
        }
      }
    }

    .contact-details-container {
      padding: 1.25vh 0 1.25vh 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      links1 {
        padding: 0 1.25vw 0 1.25vw;

        .link-container {
          padding: 1vh 0 1vh 0;
          height: 3.5vh;
          a {
            img {
              height: 3.5vh;
            }

            text {
              color: $secondary-text-colour;
                  margin-left: 1vw;
            }
          }
        }
      }

      links2 {
        padding: 0 1.25vw 0 1.25vw;

        .link-container {
          padding: 1vh 0 1vh 0;
          height: 3.5vh;
          img {
            height: 3.5vh;
          }

          text {
            color: $secondary-text-colour;
                margin-left: 1vw;
          }
        }
      }
    }
  }

  section2 {
    min-height: 5vh;

    .credits-container {
      text {
        color: $secondary-text-colour;
      }
    }
  }
}
