@import "./branding";
// @import "./media-queries";
@import "./mixins";

//Social media links at top of page
.top-social-medias {
  height: 0vh;
  width: 25vw;
  padding-top: 2.5vh;
  // position: fixed;
  // left: 70vw;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 10vh;

    li {
      a {
        img {
          height: 25%;
        }
      }
    }

    .facebookLogo {
      width: 25%;
    }
  }
}

//Nav and logo
.nav-and-logo {
  background-image: url("../assets/background.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

// Nav Bar
.nav-bar {
  z-index: 1;
  background-color: $primary-colour;

  .ul1 {
    width: 85%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    outline: none;
  }
  ul {
    padding: 0;
    list-style: none;
    background: $primary-colour;
  }
  ul li {
    display: inline-block;
    position: relative;
    line-height: 21px;
    text-align: left;
    color: $primary-text-colour;
    padding: 2.5vh 2.5vw 2.5vh 2.5vw;
    justify-content: center;
  }
  ul li a {
    display: block;
    color: $primary-text-colour;
    text-decoration: none;
  }
  ul li a:hover {
    text-decoration: none;
    color: $primary-text-colour !important;
    cursor: pointer;
  }
  ul li ul.ul2 {
    min-width: 15vw; /* Set width of the dropdown */
    background: $primary-colour;
    display: none;
    position: absolute;
    z-index: 999;
    left: 0;
    margin-top: 1rem;
  }
  ul li:hover ul.ul2 {
    display: block; /* Display the dropdown */
  }
  ul li ul.ul2 li {
    display: block;
  }
}

.logo-only-container {
  height: 50vh;
  img {
    width: 40vh;
    margin-top: 10vh;
  }
}

.text-only-container {
  h1 {
    font-family: $title-text-font;
    font-weight: 10;
    letter-spacing: 0.5vw;
    margin-bottom: 0;
    color: $primary-text-colour;
    // font-size: 3em;
  }
  h3 {
    font-family: $title-text-font;
    font-weight: 10;
    letter-spacing: 0.4vw;
    margin-top: 0;
    color: $primary-text-colour;
    // font-size: 1.8em;
  }
}

.arrow-down {
  img {
    height: 2vh;
    color: $primary-text-colour;
  }
}

.bounce {
  animation: bounce 2s infinite;
}

// About section
.about-container {
  background-color: $primary-colour;
  width: 100vw;
  max-width: 100%;
  height: auto;

  .headshot-container {
    .headshot-and-text-container {
      img {
        height: 30vh;
        border-radius: 50%;
      }
    }
  }

  h2 {
    font-family: $title-text-font;
    letter-spacing: 0.5vw;
    // font-size: 3.5em;
    padding-top: 3vh;
    color: $primary-text-colour;
  }

  .aboutImg1-container,
  .aboutImg2-container {
    height: 60vh;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }

  .aboutImg1-container {
    background-image: url("../assets/gallery/IMG-20191125-WA0025.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .aboutImg2-container {
    background-image: url("../assets/gallery/IMG_20200123_143606.jpg");
    background-position: right;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about-text {
    margin-left: 12.5vw;
    margin-right: 12.5vw;
    display: flex;
    flex-direction: column;
    padding-top: 5vh;
    padding-bottom: 5vh;

    .about-sub-text {
      height: auto;
      p {
        // font-size: 1.25em;
        font-family: $primary-text-font;
        float: left;
        color: $primary-text-colour;
      }
    }
  }

  .headshot-container {
    padding-bottom: 2.5vh;
    padding-top: 2vh;
    .name-and-role {
      margin-top: 2.5vh;

      h3 {
        font-family: $title-text-font;
        font-weight: 10;
        letter-spacing: 0.2vw;
        margin-top: 0;
        color: $primary-text-colour;
      }

      h4 {
        font-family: $title-text-font;
        font-weight: 10;
        letter-spacing: 0.2vw;
        margin-top: 0;
        color: $primary-text-colour;
      }
    }
  }
}

// Work for/with us section
.work-container {
  background: $primary-colour;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 5vh;
  padding-bottom: 5vh;

  .work-with-us-container {
    background: url("../assets/workWithUsImage.jpg") no-repeat 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }

  .work-for-us-container {
    background: url("../assets/joinUsImage.jpg") no-repeat 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }

  .work-sub-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .work-for-us-image-container {
      img {
        height: 100vh;
        width: 50vw;
        opacity: 0.6;
        :hover {
          opacity: 0.2;
        }
      }
    }

    .work-with-us-image-container {
      img {
        height: 100vh;
        width: 49.21vw;
        opacity: 0.6;
        :hover {
          opacity: 0.2;
        }
      }
    }

    .work-title {
      h1 {
        font-family: $title-text-font;
        color: $secondary-text-colour;
        // font-size: 3.5em;
        letter-spacing: 0.5vw;
      }
    }

    .work-text {
      margin-left: 7.5vw;
      margin-right: 7.5vw;
      background: $primary-colour;
      opacity: 0.9;
      height: 25vh;
      border-radius: 0.5em;
      padding-left: 2vw;
      padding-right: 2vw;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        color: $primary-text-colour;
        // font-size: 1.3em;
        font-family: $primary-text-font;
      }
    }

    .work-button {
      grid-area: button;

      a {
        background: $primary-colour;
        border-radius: 1em;
        border: none;
        padding: 3vh;
        font-family: $primary-text-font;
        text-decoration: none;
        color: $primary-text-colour;
        // font-size: 1.5em;
      }
    }
  }
}

.cookies-popup-container {
  background-color: whitesmoke;
  .cookies-popup {
    // width: 60vw;
    margin-left: 25vw;
    margin-right: 25vw;

    p {
      // font-size: 0.8em;
      display: inline;
    }

    img {
      padding-top: 1vh;
      height: 1.5vh;
      cursor: pointer;
    }
  }
}
